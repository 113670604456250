import { Common, Resources } from '@otter-co/ottai-shared';
import React, { PropsWithChildren, useMemo } from 'react';

export const ListView = ( {
    knownUsers = [],
    knownTextDocuments = [],
    knownAIImages = [],
    currentDocumentID = null,
    onSelect = () => { },
    onDeleteDocument = () => { },
    onDeleteAIImage = () => { },
}: PropsWithChildren<{
    knownUsers?: Resources.User[];
    knownTextDocuments?: Resources.TextDocument[];
    knownAIImages?: Resources.AIImage[];

    currentDocumentID?: string | null;

    onSelect?: ( docID: string ) => void;

    onDeleteDocument?: ( docID: string ) => void;
    onDeleteAIImage?: ( aiImageID: string ) => void;
}> ) => 
{
    const mixedDocs = useMemo( () =>
        [ ...knownTextDocuments, ...knownAIImages ]
            .sort( ( a, b ) => ( new Date( a.createdOn ).getTime() ) - ( new Date( b.createdOn ).getTime() ) )
        , [ knownTextDocuments, knownAIImages ]
    );

    const listData = useMemo( () => mixedDocs.map( doc =>
    {
        const user = knownUsers.find( u => u.id === doc.createdByID );

        const openDoc = () => onSelect( doc.id );
        const deleteDoc = doc.type === Common.ResourceTypes.TextDocument
            ? () => onDeleteDocument( doc.id )
            : () => onDeleteAIImage( doc.id );

        return (
            <tr className={ currentDocumentID === doc.id ? 'selected' : '' } key={ doc.id }>
                <td><button onClick={ openDoc }>Open</button></td>
                <td>{ doc.name }</td>
                <td>{ doc.type }</td>
                <td>{ doc.createdOn?.toLocaleString() }</td>
                <td> { user?.displayName ?? "Unkown User" } </td>
                <td> <button onClick={ deleteDoc }>Delete</button> </td>
            </tr>
        );
    }
    ), [ currentDocumentID, knownUsers, mixedDocs, onSelect, onDeleteDocument, onDeleteAIImage ] );

    return (
        <table className='ListView'>
            <thead>
                <tr>
                    <th colSpan={ 2 }>Name</th>
                    <th>Type</th>
                    <th>Created</th>
                    <th>Created By</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                { listData }
            </tbody>
        </table>
    );
};