import { Common } from "@otter-co/ottai-shared";
import { OttAIClient } from "../OttAIClient";

export abstract class BaseResource<T extends object>
{
    constructor (
        protected _client: OttAIClient,
    )
    { }

    
}