import React, { KeyboardEvent, PropsWithChildren, useCallback } from 'react';
import { useInputValue } from '../../../state/hooks/useInputValue';

import './ChatInput.Style.scss';
import { useCheckboxValue } from '../../../state/hooks/useCheckboxValue';

export const ChatInput = ( {
    disabled = false,
    onInput = () => { },
}: PropsWithChildren<{
    disabled?: boolean;
    onInput?: ( input: string, autoResponse: boolean ) => void;
}> ) => 
{
    const [ content, setCurrentInputValue, setCurrentInput ] = useInputValue( '' );
    const [ autoResponseEnabled, setAutoResponseValue, setAutoResponse ] = useCheckboxValue( true );

    const sendFunction = useCallback( () =>
    {
        onInput( content, autoResponseEnabled );
        setCurrentInput( '' );
    }, [ onInput, setCurrentInput, content, autoResponseEnabled ] );

    const sendMessage = useCallback(
        ( e: React.FormEvent<HTMLFormElement> ) => 
        {
            e.preventDefault();
            sendFunction();
        }, [ sendFunction ] );

    const sendOnMetaEnter = useCallback( ( e: KeyboardEvent<HTMLTextAreaElement> ) => 
    {
        if ( e.key === 'Enter' && e.metaKey )
        {
            e.preventDefault();
            sendFunction();
        }
    }, [ sendFunction ] );

    const sendDisabled = (
        disabled ||
        content.trim().length === 0
    );

    return (
        <form className="ChatInput" onSubmit={ sendMessage }>
            <label id="AutoResponse">
                <span>Enable Auto Response</span>
                <input disabled={ disabled } type="checkbox" onChange={ setAutoResponseValue } checked={ autoResponseEnabled } />
            </label>
            <label id="Inputs">
                <textarea disabled={ disabled } onKeyDown={ sendOnMetaEnter } onChange={ setCurrentInputValue } value={ content } />
                <button disabled={ sendDisabled }>Send</button>
            </label>
        </form>
    );
};