import { AIImages } from "./resources/AIImages";
import { ChatMessages } from "./resources/ChatMessages";
import { Interactors } from "./resources/Interactors";
import { Spaces } from "./resources/Spaces";
import { TextDocuments } from "./resources/TextDocuments";
import { Users } from "./resources/Users";

export interface OttAIClientOptions 
{
    host: string;
}

export class OttAIClient 
{
    public users: Users = new Users( this );
    public spaces: Spaces = new Spaces( this );
    public interactors: Interactors = new Interactors( this );
    public chatMessages: ChatMessages = new ChatMessages( this );
    public textDocuments: TextDocuments = new TextDocuments( this );
    public images: AIImages = new AIImages( this );

    constructor (
        public options: OttAIClientOptions = { host: 'localhost' }
    ) { };

    public async fetchAPI<T extends object = object> ( path: string, options: RequestInit = {} ) 
    {
        const response = await fetch(
            `${ this.options.host }/api/${ path }`,
            {
                ...options,
                credentials: 'include'
            } );

        return await response.json() as T;
    }

    public async fetchAuth<T extends object = object> ( path: string, options: RequestInit = {} ) 
    {
        const response = await fetch(
            `${ this.options.host }/auth/${ path }`,
            {
                ...options,
                credentials: 'include'
            }
        );

        return await response.json() as T;
    }
}