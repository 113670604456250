import { Reducer, useCallback, useReducer } from 'react';

type ArrayReducerAction<T> =
    {
        type: "add",
        payload: T;
    } | {
        type: "remove",
        payload: T;
    } | {
        type: "clear",
        payload?: null;
    };

export function useArrayReducer<T> ( initial: T[] = [] )
{
    function arrayReducer ( state: T[], action: ArrayReducerAction<T> )
    {
        switch ( action.type )
        {
            case 'add':
                return [ ...state, action.payload ];
            case 'remove':
                return state.filter( item => item !== action.payload );
            case 'clear':
                return [];
            default:
                return state;
        }
    }

    const [ target, dispatch ] = useReducer( arrayReducer, initial );

    const add = useCallback( ( payload: T ) => dispatch( { type: 'add', payload } ), [ dispatch ] );
    const remove = useCallback( ( payload: T ) => dispatch( { type: 'remove', payload } ), [ dispatch ] );
    const clear = useCallback( () => dispatch( { type: 'clear' } ), [ dispatch ] );

    return [ target, add, remove, clear ] as const;
}