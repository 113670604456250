import { useState } from 'react';

export function useCheckboxValue ( initialValue: boolean ) 
{
    const [ value, setValue ] = useState( initialValue );

    const setEventValue = ( event: React.ChangeEvent<HTMLInputElement> ) => setValue( event.target.checked );

    return [
        value,
        setEventValue,
        setValue
    ] as const;
}