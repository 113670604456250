import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { OttAIClientContext } from '../../../state/contexts/ottAIClientContext';
import { Requests, Resources, SpaceEvents } from '@otter-co/ottai-shared';
import { TextEditor } from '../../Basic/TextEditor/TextEditor';
import { useToggle } from '../../../state/hooks/useToggleValue';

import './TextDocumentView.Style.scss';
import { useInputValue } from '../../../state/hooks/useInputValue';
import { useCheckboxValue } from '../../../state/hooks/useCheckboxValue';
import { SpaceEventEmitter } from '../../../../lib/ottAI/SpaceEventEmitter';

export const TextDocumentView = ( {
    disabled = false,
    spaceEvents = null,
    spaceID = null,
    textDoc = null,
    versionID = null,
    onSave = () => { },
}: PropsWithChildren<{
    disabled?: boolean;
    spaceEvents?: SpaceEventEmitter | null;
    spaceID?: string | null;
    textDoc?: Resources.TextDocument | null;
    versionID?: number | null;
    onSave?: ( docID: string, data: Requests.TextDocumentPatchRequest ) => void;
}> ) =>
{
    const ottAI = useContext( OttAIClientContext );

    const [ loading, setLoading ] = useState<boolean>( false );

    const [ textDocContent, setTextDocContent ] = useState<Resources.TextDocumentContent | null>( null );

    const [ currentContent, setCurrentContent ] = useState<string>( '' );

    const [ settingsOpen, toggleSettingsOpen ] = useToggle( false );

    const [ textDocumentName, setTextDocumentNameValue, setTextDocumentName ] = useInputValue( '' );
    const [ textDocumentDescription, setTextDocumentDescriptionValue, setTextDocumentDescription ] = useInputValue( '' );
    const [ textDocumentType, setTextDocumentTypeValue, setTextDocumentType ] = useInputValue( Resources.TextDocumentTypes.Markdown );

    const [ isInEdit, setIsInEditValue, setIsInEdit ] = useCheckboxValue( false );

    useEffect(
        () => 
        {
            setTextDocumentName( textDoc?.name ?? '' );
            setTextDocumentDescription( textDoc?.description ?? '' );
            setTextDocumentType( textDoc?.textType ?? Resources.TextDocumentTypes.Markdown );
        },
        [
            setTextDocumentName, setTextDocumentDescription, setTextDocumentType,
            textDoc?.name, textDoc?.description, textDoc?.textType
        ]
    );

    useEffect(
        () => 
        {
            if ( !spaceID || !textDoc?.id ) return;
            setLoading( true );
            ottAI.textDocuments
                .getTextDocumentContent( spaceID, textDoc.id )
                .catch( () => null )
                .then( content =>
                {
                    setTextDocContent( content );
                    setCurrentContent( content?.content! );
                } )
                .finally( () => setLoading( false ) );
        },
        [ ottAI, spaceID, textDoc?.id ]
    );

    useEffect(
        () => 
        {
            if ( !spaceEvents ) return;

            const handleContentAdded = ( content: Resources.TextDocumentContent ) =>
            {
                if ( content.textDocumentID !== textDoc?.id ) return;

                setTextDocContent( content );
                setCurrentContent( content.content );
            };

            spaceEvents.addEventListener( SpaceEvents.SpaceEventType.TextDocumentContentAdded, handleContentAdded );

            return () =>
                spaceEvents.removeEventListener( SpaceEvents.SpaceEventType.TextDocumentContentAdded, handleContentAdded );
        },
        [ spaceEvents, textDoc?.id ]
    );


    const saveSettings = useCallback(
        () => onSave( textDoc?.id!, { name: textDocumentName, description: textDocumentDescription, textType: textDocumentType as Resources.TextDocumentTypes } ),
        [ onSave, textDoc?.id, textDocumentName, textDocumentDescription, textDocumentType ]
    );

    const saveDocument = useCallback(
        () => 
        {
            if ( !spaceID || !textDoc?.id ) return;

            setLoading( true );
            ottAI.textDocuments
                .updateTextDocumentContent( spaceID, textDoc?.id, { content: currentContent } )
                .finally( () => setLoading( false ) );
        },
        [ ottAI, setLoading, setCurrentContent, spaceID, textDoc?.id, currentContent ]
    );

    const disabledInput = (
        loading ||
        !textDoc
    );

    const contentDirty = (
        textDocContent?.content !== currentContent
    );

    const settingsDirty = (
        textDoc?.name !== textDocumentName ||
        textDoc?.description !== textDocumentDescription
    );

    return (
        <div className="TextDocumentView">
            <header>
                <button disabled={ disabledInput } onClick={ toggleSettingsOpen }>
                    { settingsOpen
                        ? "Close Settings"
                        : "Open Settings" }
                </button>
                { settingsOpen && (
                    <>
                        <button disabled={ disabledInput || settingsDirty } onClick={ saveSettings }>Save Settings</button>
                        <form>
                            <label>
                                <span>Document Name:</span>
                                <input disabled={ disabledInput } type="text" onChange={ setTextDocumentNameValue } value={ textDocumentName } />
                            </label>
                            <label>
                                <span>Document Description:</span>
                                <textarea disabled={ disabledInput } onChange={ setTextDocumentDescriptionValue } value={ textDocumentDescription } />
                            </label>
                            <label>
                                <span>Document Type:</span>
                                <select value={ textDocumentType } onChange={ setTextDocumentTypeValue }>
                                    <option value={ Resources.TextDocumentTypes.Markdown }>Markdown</option>
                                    <option value={ Resources.TextDocumentTypes.JSON }>JSON</option>
                                </select>
                            </label>
                        </form>
                    </>
                ) }
                <span id="SaveSpan">
                    <button disabled={ disabledInput || !contentDirty } onClick={ saveDocument }>Save</button>
                    <label>
                        <span>Edit</span>
                        <input disabled={ disabledInput } type="checkbox" checked={ isInEdit } onChange={ setIsInEditValue } />
                    </label>
                </span>
            </header>
            <TextEditor disabled={ disabledInput } preview={ !isInEdit } value={ currentContent } onChange={ setCurrentContent } onSave={ saveDocument } />
        </div>
    );
};