import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { OttAIClientContext } from '../../../state/contexts/ottAIClientContext';
import { Resources } from '@otter-co/ottai-shared';

import './UserDisplay.style.scss';
import { useToggle } from '../../../state/hooks/useToggleValue';

export const UserDisplay = (
    {
        userID = null,
    }: PropsWithChildren<{
        userID?: string | null;
    }>,
) =>
{
    const ottAI = useContext( OttAIClientContext );

    const [ loading, setLoading ] = useState( false );

    const [ currentUser, setCurrentUser ] = useState<Resources.User | null>( null );
    const [ showUserID, toggleUserID ] = useToggle( false );

    useEffect( () => 
    {
        if ( userID )
        {
            setLoading( true );
            ottAI.users
                .getUser( userID )
                .then( setCurrentUser )
                .finally( () => setLoading( false ) );
        }
        else
            setCurrentUser( null );
    },
        [ ottAI, userID, setCurrentUser, setLoading ]
    );

    const displayName = currentUser
        ? currentUser.displayName ?? "No Display Name"
        : "Loading";

    const email = currentUser
        ? currentUser.email ?? "No Email"
        : "Loading";

    const emailDisplay = showUserID
        ? userID
        : email;

    return (
        <div className="UserDisplay">
            <h2 onClick={ toggleUserID }>{ loading ? "Loading..." : displayName }</h2>
            <p>{ loading ? "Loading..." : emailDisplay }</p>
        </div>
    );
};