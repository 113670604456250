import React, { PropsWithChildren, useMemo } from 'react';
import { SpaceEventEmitter } from '../../../../lib/ottAI/SpaceEventEmitter';
import { Resources, Common, Requests } from '@otter-co/ottai-shared';
import { TextDocumentView } from '../../TextDocuments/TextDocumentView/TextDocumentView';
import { AIImageView } from '../../AIImages/AIImageView/AIImageView';

import './DocumentView.style.scss';

export const DocumentView = ( {
    disabled = false,
    spaceEvents = null,
    spaceID = null,
    lockedAIImages = [],
    targetDocument = null,

    onDocSave = () => { },
    onImageSave = () => { },
}: PropsWithChildren<{
    disabled?: boolean;
    spaceEvents?: SpaceEventEmitter | null;
    spaceID?: string | null;
    lockedAIImages?: string[];
    targetDocument?: Resources.TextDocument | Resources.AIImage | null;

    onDocSave?: ( docID: string, doc: Requests.TextDocumentPatchRequest ) => void;
    onImageSave?: ( imageID: string, image: Requests.AIImagePatchRequest ) => void;
}> ) => 
{
    const content = useMemo( () => 
    {
        switch ( targetDocument?.type )
        {
            case Common.ResourceTypes.TextDocument:
                return (
                    <TextDocumentView
                        disabled={ disabled }
                        spaceEvents={ spaceEvents }
                        spaceID={ spaceID }
                        textDoc={ targetDocument }
                        onSave={ onDocSave }
                    />
                );
            case Common.ResourceTypes.AIImage:
                return (
                    <AIImageView
                        disabled={ disabled }
                        spaceEvents={ spaceEvents }
                        spaceID={ spaceID }
                        image={ targetDocument }
                        lockedImages={ lockedAIImages }
                        onSave={ onImageSave }
                    />
                );

            default:
                return null;
        }
    },
        [ targetDocument, disabled, spaceEvents, spaceID, lockedAIImages, onDocSave, onImageSave ]
    );

    return (
        <div className='DocumentView'>
            { content }
        </div>
    );
};