import { Resources, Requests } from '@otter-co/ottai-shared';
import { BaseResource } from './BaseResource';

export class Interactors extends BaseResource<Resources.Interactor>
{
    public async getKnownModels ( spaceID: string )
    {
        return await this._client.fetchAPI<Resources.OttModel[]>( `spaces/${ spaceID }/interactors/models` );
    }

    public async createInteractor ( spaceID: string, interactor: Requests.InteractorPostRequest )
    {
        return await this._client.fetchAPI<Resources.Interactor>( `spaces/${ spaceID }/interactors`, {
            method: 'POST',
            body: JSON.stringify( interactor ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async getInteractorsForSpace ( spaceID: string )
    {
        return await this._client.fetchAPI<Resources.Interactor[]>( `spaces/${ spaceID }/interactors` );
    }

    public async getInteractor ( spaceID: string, interactorID: string )
    {
        return await this._client.fetchAPI<Resources.Interactor>( `spaces/${ spaceID }/interactors/${ interactorID }` );
    }

    public async updateInteractor ( spaceID: string, interactorID: string, interactor: Requests.InteractorPatchRequest )
    {
        return await this._client.fetchAPI<Resources.Interactor>( `spaces/${ spaceID }/interactors/${ interactorID }`, {
            method: 'PATCH',
            body: JSON.stringify( interactor ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async deleteInteractor ( spaceID: string, interactorID: string )
    {
        return await this._client.fetchAPI( `spaces/${ spaceID }/interactors/${ interactorID }`, {
            method: 'DELETE'
        } );
    }

    public async GetInteractorResponse ( spaceID: string, interactorID: string )
    {
        return await this._client.fetchAPI<Resources.ChatMessage[]>( `spaces/${ spaceID }/interactors/${ interactorID }/response` );
    }
}