import { DevelopmentEnv } from './development';
import { ProductionEnv } from './production';
import { LocalEnv } from './local';

export interface Env 
{
    production: boolean;

    apiBasePath: string;
    imageBasePath: string;
}

export function getEnv (): Env
{
    switch ( process.env.REACT_APP_OTTAI_ENV )
    {
        case 'production':
            return new ProductionEnv();

        case 'development':
            return new DevelopmentEnv();

        default:
        case 'local':
            return new LocalEnv();
    }
}