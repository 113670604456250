import { ChangeEvent, useCallback, useState } from "react";

export function useToggle ( initial: boolean ): [
    value: boolean,
    toggle: () => void,
    setValue: ( value: boolean ) => void,
    setInputValue: ( event: ChangeEvent<HTMLInputElement> ) => void,
]
{
    const [ value, setValue ] = useState( initial );
    const toggle = useCallback( () => setValue( !value ), [ setValue, value ] );
    const setInputValue = useCallback( ( event: ChangeEvent<HTMLInputElement> ) => setValue( event.target.checked ), [ setValue ] );

    return [ value, toggle, setValue, setInputValue ];
}