import { Resources, Requests } from "@otter-co/ottai-shared";
import { BaseResource } from "./BaseResource";

export class TextDocuments extends BaseResource<Resources.TextDocument>
{
    public async createTextDocument ( spaceID: string, textDocument: Requests.TextDocumentPostRequest ) 
    {
        return await this._client.fetchAPI<Resources.TextDocument>( `spaces/${ spaceID }/documents`, {
            method: 'POST',
            body: JSON.stringify( textDocument ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async getTextDocumentsForSpace ( spaceID: string )
    {
        return await this._client.fetchAPI<Resources.TextDocument[]>( `spaces/${ spaceID }/documents` );
    }

    public async getTextDocument ( spaceID: string, textDocumentID: string ) 
    {
        return await this._client.fetchAPI<Resources.TextDocument>( `spaces/${ spaceID }/documents/${ textDocumentID }` );
    }

    public async updateTextDocument ( spaceID: string, textDocumentID: string, textDocument: Requests.TextDocumentPatchRequest )
    {
        return await this._client.fetchAPI<Resources.TextDocument>( `spaces/${ spaceID }/documents/${ textDocumentID }`, {
            method: 'PATCH',
            body: JSON.stringify( textDocument ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async deleteTextDocument ( spaceID: string, textDocumentID: string )
    {
        return await this._client.fetchAPI( `spaces/${ spaceID }/documents/${ textDocumentID }`, {
            method: 'DELETE'
        } );
    }

    public async getTextDocumentContent ( spaceID: string, textDocumentID: string, version?: number | null ) 
    {
        return await this._client.fetchAPI<Resources.TextDocumentContent>(
            version
                ? `spaces/${ spaceID }/documents/${ textDocumentID }/content/${ version }`
                : `spaces/${ spaceID }/documents/${ textDocumentID }/content`
        );
    }

    public async getAllVersionsOfTextDocumentContent ( spaceID: string, textDocumentID: string ) 
    {
        return await this._client.fetchAPI<Resources.TextDocumentContent[]>( `spaces/${ spaceID }/documents/${ textDocumentID }/content/all` );
    }

    public async updateTextDocumentContent ( spaceID: string, textDocumentID: string, textDocumentContent: Requests.TextDocumentContentPatchRequest ) 
    {
        return await this._client.fetchAPI<Resources.TextDocumentContent>( `spaces/${ spaceID }/documents/${ textDocumentID }/content`, {
            method: 'PATCH',
            body: JSON.stringify( textDocumentContent ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }
}