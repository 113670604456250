import React, { ChangeEvent, KeyboardEvent, PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';
import
{
    useTextAreaMarkdownEditor,
    boldCommand,
    checkedListCommand,
    codeBlockCommand,
    codeCommand,
    headingLevel1Command,
    headingLevel2Command,
    headingLevel3Command,
    headingLevel4Command,
    headingLevel5Command,
    headingLevel6Command,
    imageCommand,
    italicCommand,
    linkCommand,
    orderedListCommand,
    quoteCommand,
    strikethroughCommand,
    unorderedListCommand
} from 'react-mde';

import './TextEditor.Style.scss';
import { useCheckboxValue } from '../../../state/hooks/useCheckboxValue';
import { MarkDownView } from '../MarkDownView';

const mdeCommands = {
    commandMap: {
        bold: boldCommand,
        checkedList: checkedListCommand,
        code: codeCommand,
        codeBlock: codeBlockCommand,
        heading: headingLevel1Command,
        heading1: headingLevel1Command,
        heading2: headingLevel2Command,
        heading3: headingLevel3Command,
        heading4: headingLevel4Command,
        heading5: headingLevel5Command,
        heading6: headingLevel6Command,
        image: imageCommand,
        italic: italicCommand,
        link: linkCommand,
        orderedList: orderedListCommand,
        quote: quoteCommand,
        strikethrough: strikethroughCommand,
        unorderedList: unorderedListCommand,
    }
};

export const TextEditor = ( {
    disabled = false,
    value = '',
    preview = false,
    onSave = () => { },
    onChange = () => { },
}: PropsWithChildren<{
    disabled?: boolean;
    value?: string;
    preview?: boolean;
    onChange?: ( value: string ) => void;
    onSave?: () => void;
}> ) => 
{
    const { ref, commandController } = useTextAreaMarkdownEditor( mdeCommands );

    const changeCallback = useCallback( ( e: ChangeEvent<HTMLTextAreaElement> ) =>
    {
        onChange( e.target.value );
    }, [ onChange ] );

    const saveCallback = useCallback( () =>
    {
        onSave();
    }, [ onSave ] );

    const cmdSaveCallback = useCallback( ( e: KeyboardEvent ) => 
    {
        if ( e.metaKey && e.key === 's' )
        {
            e.preventDefault();
            saveCallback();
        }
    }, [ saveCallback ] );


    const boldCallback = useCallback( () => commandController.executeCommand( 'bold' ), [ commandController ] ),
        italicCallback = useCallback( () => commandController.executeCommand( 'italic' ), [ commandController ] ),
        strikethroughCallback = useCallback( () => commandController.executeCommand( 'strikethrough' ), [ commandController ] ),
        codeCallback = useCallback( () => commandController.executeCommand( 'code' ), [ commandController ] ),
        codeBlockCallback = useCallback( () => commandController.executeCommand( 'codeBlock' ), [ commandController ] ),
        heading1Callback = useCallback( () => commandController.executeCommand( 'heading1' ), [ commandController ] ),
        heading2Callback = useCallback( () => commandController.executeCommand( 'heading2' ), [ commandController ] ),
        heading3Callback = useCallback( () => commandController.executeCommand( 'heading3' ), [ commandController ] ),
        heading4Callback = useCallback( () => commandController.executeCommand( 'heading4' ), [ commandController ] ),
        heading5Callback = useCallback( () => commandController.executeCommand( 'heading5' ), [ commandController ] ),
        heading6Callback = useCallback( () => commandController.executeCommand( 'heading6' ), [ commandController ] ),
        quoteCallback = useCallback( () => commandController.executeCommand( 'quote' ), [ commandController ] ),
        orderedListCallback = useCallback( () => commandController.executeCommand( 'orderedList' ), [ commandController ] ),
        unorderedListCallback = useCallback( () => commandController.executeCommand( 'unorderedList' ), [ commandController ] ),
        checkedListCallback = useCallback( () => commandController.executeCommand( 'checkedList' ), [ commandController ] ),
        imageCallback = useCallback( () => commandController.executeCommand( 'image' ), [ commandController ] ),
        linkCallback = useCallback( () => commandController.executeCommand( 'link' ), [ commandController ] );

    const editorButtons = (
        <div>
            <button disabled={ disabled } onClick={ boldCallback }>Bold</button>
            <button disabled={ disabled } onClick={ italicCallback }>Italic</button>
            <button disabled={ disabled } onClick={ strikethroughCallback }>Strike</button>
            <button disabled={ disabled } onClick={ codeCallback }>Code</button>
            <button disabled={ disabled } onClick={ codeBlockCallback }>Code Block</button>
            <button disabled={ disabled } onClick={ heading1Callback }>H1</button>
            <button disabled={ disabled } onClick={ heading2Callback }>H2</button>
            <button disabled={ disabled } onClick={ heading3Callback }>H3</button>
            <button disabled={ disabled } onClick={ heading4Callback }>H4</button>
            <button disabled={ disabled } onClick={ heading5Callback }>H5</button>
            <button disabled={ disabled } onClick={ heading6Callback }>H6</button>
            <button disabled={ disabled } onClick={ quoteCallback }>Quote</button>
            <button disabled={ disabled } onClick={ linkCallback }>Link</button>
            <button disabled={ disabled } onClick={ imageCallback }>Image</button>
            <button disabled={ disabled } onClick={ orderedListCallback }>OL</button>
            <button disabled={ disabled } onClick={ unorderedListCallback }>UL</button>
            <button disabled={ disabled } onClick={ checkedListCallback }>CL</button>
        </div>
    );

    const content = preview
        ? <MarkDownView markdown={ value } />
        : <textarea disabled={ disabled } ref={ ref } onChange={ changeCallback } value={ value } />;

    return (
        <div className="TextEditor" onKeyDown={ cmdSaveCallback }>
            { !preview && editorButtons }
            { content }
        </div>
    );
};