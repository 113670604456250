import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import { getEnv } from './env';
import { App } from './App/App';
import { OttAIClient } from './lib/ottAI';
import { DefaultConverter } from './lib/showdown';

import { OttAIClientContext } from './App/state/contexts/ottAIClientContext';

const env = getEnv();

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
);

const ottapi = new OttAIClient( { host: env.apiBasePath } );

root.render(
  <React.StrictMode>
    <OttAIClientContext.Provider value={ ottapi }>
      <App />
    </OttAIClientContext.Provider>
  </React.StrictMode>
);