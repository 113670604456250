import React, { ChangeEvent, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OttAIClientContext } from '../../../state/contexts/ottAIClientContext';
import { Requests, Resources } from '@otter-co/ottai-shared';
import { useInputValue } from '../../../state/hooks/useInputValue';

import './InteractorList.Style.scss';

export const InteractorList = ( {
    disabled = false,
    interactors = [],
    lockedInteractors = [],
    currentInteractorID = null,
    onChange = () => { },
    onCreate = () => { },
    onDelete = () => { },
}: PropsWithChildren<{
    disabled?: boolean;
    interactors?: Resources.Interactor[];
    lockedInteractors?: string[];
    currentInteractorID?: string | null;
    onChange?: ( interactorID: string ) => void;
    onCreate?: ( data: Requests.InteractorPostRequest ) => void;
    onDelete?: ( interactorID: string ) => void;
}>,
) => 
{
    const [ newInteractorName, setNewInteractorNameValue, setInteractorName ] = useInputValue( '' );

    const interactorChangeCallback = useCallback(
        ( e: ChangeEvent<HTMLSelectElement> ) => onChange( e.target.value ),
        [ onChange ]
    );

    const createInteractor = useCallback(
        ( e: React.FormEvent<HTMLFormElement> ) => 
        {
            e.preventDefault();
            onCreate( { name: newInteractorName, ottModelID: "test" } );
            setInteractorName( '' );
        },
        [ onCreate, setInteractorName, newInteractorName ]
    );

    const deleteInteractorCallback = useCallback(
        () =>
        {
            onDelete( currentInteractorID ?? '' );
            onChange( '' );
        },
        [ onDelete, onChange, currentInteractorID ]
    );

    const interactorOptions = useMemo(
        () => interactors.map( interactor => <option key={ interactor.id } value={ interactor.id }>{ interactor.name }</option> ),
        [ interactors ]
    );

    return (
        <div className="InteractorList">
            <form onSubmit={ createInteractor }>
                <input type="text" placeholder="New Interactor Name" onChange={ setNewInteractorNameValue } value={ newInteractorName } />
                <button disabled={ disabled || !newInteractorName }>New Interactor</button>
            </form>

            <select onChange={ interactorChangeCallback } value={ currentInteractorID ?? '' }>
                <option value="">Select an interactor</option>
                { interactorOptions }
            </select>

            <button disabled={ disabled || currentInteractorID === '' } onClick={ deleteInteractorCallback }>Delete Current</button>
        </div>
    );
};