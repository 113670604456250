import { useCallback, useEffect, useMemo, useState } from 'react';
import { Requests, Resources, SpaceEvents } from '@otter-co/ottai-shared';

import { OttAIClient } from '../../../lib/ottAI';
import { SpaceEventEmitter } from '../../../lib/ottAI/SpaceEventEmitter';
import { useArrayReducer } from '../reducers/arrayReducer';

export function useSpaceState ( ottAI: OttAIClient, currentUserID?: string | null, spaceID?: string | null )
{
    const [ loading, setLoading ] = useState( false );

    const [ space, setSpace ] = useState<Resources.Space | null>( null );

    const [ knownUsers, setKnownUsers ] = useState<Resources.User[]>( [] );
    const [ onlineUsers, addOnlineUser, removeOnlineUser, ] = useArrayReducer<string>();

    const [ knownInteractors, setKnownInteractors ] = useState<Resources.Interactor[]>( [] );
    const [ knownModels, setKnownModels ] = useState<Resources.OttModel[]>( [] );

    const [ knownDocuments, setKnownDocuments ] = useState<Resources.TextDocument[]>( [] );
    const [ knownAIImages, setKnownAIImages ] = useState<Resources.AIImage[]>( [] );

    const [ lockedInteractors, setLockedInteractors ] = useState<string[]>( [] );
    const [ lockedAIImages, setLockedAIImages ] = useState<string[]>( [] );

    const [ spaceEvents, setSpaceEvents ] = useState<SpaceEventEmitter | null>( null );

    const [ currentInteractorID, setCurrentInteractorID ] = useState<string | null>( null );

    const [ currentDocumentID, setCurrentDocumentID ] = useState<string | null>( null );

    useEffect( () =>
    {
        if ( !spaceID ) return;

        let spaceEvents: SpaceEventEmitter | null = null;

        loadSpace();
        async function loadSpace () 
        {
            if ( !spaceID ) return;
            setLoading( true );

            spaceEvents = ottAI.spaces.subscribe( spaceID );
            spaceEvents.addEventListener(
                SpaceEvents.SpaceEventType.UserStatusUpdate,
                ( { id, online } ) => online ? addOnlineUser( id ) : removeOnlineUser( id )
            );
            spaceEvents.addEventListener( SpaceEvents.SpaceEventType.UserDeleted, async ( { id } ) => 
            {
                if ( id === currentUserID )
                    window.location.reload();
                else
                    setKnownUsers( await Promise.all( space.members.map( member => ottAI.users.getUser( member ) ) ) );
            } );

            spaceEvents.addEventListener( SpaceEvents.SpaceEventType.SpaceUpdate,
                ( space ) => setSpace( space )
            );

            setSpaceEvents( spaceEvents );

            const space = await ottAI.spaces.getSpace( spaceID );
            setSpace( space );

            const users = await Promise.all( space.members.map( member => ottAI.users.getUser( member ) ) );
            setKnownUsers( users );

            const documents = await ottAI.textDocuments.getTextDocumentsForSpace( spaceID );
            setKnownDocuments( documents );

            const images = await ottAI.images.getAIImagesForSpace( spaceID );
            setKnownAIImages( images );

            const interactors = await ottAI.interactors.getInteractorsForSpace( spaceID );
            setKnownInteractors( interactors );

            const models = await ottAI.interactors.getKnownModels( spaceID );
            setKnownModels( models );

            setLoading( false );
        }

        return () => 
        {
            if ( spaceEvents )
                spaceEvents.close();

            setSpaceEvents( null );
            setSpace( null );
            setKnownUsers( [] );
            setKnownDocuments( [] );
            setKnownInteractors( [] );
        };
    }, [
        ottAI, currentUserID, spaceID,
        addOnlineUser, removeOnlineUser,
        setLoading, 
        setSpace, setKnownUsers, setKnownDocuments, setKnownAIImages, setKnownInteractors
    ] );

    useEffect( () =>
    {
        if ( !spaceID || !spaceEvents ) return;

        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.TextDocumentAdded, ( texDoc: Resources.TextDocument ) => setKnownDocuments( [ ...knownDocuments, texDoc ] ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.TextDocumentUpdate, ( texDoc: Resources.TextDocument ) => setKnownDocuments( knownDocuments.map( doc => doc.id === texDoc.id ? texDoc : doc ) ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.TextDocumentDeleted, ( { id }: { id: string; } ) => setKnownDocuments( knownDocuments.filter( doc => doc.id !== id ) ) );

        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.AIImageAdded, ( image: Resources.AIImage ) => setKnownAIImages( [ ...knownAIImages, image ] ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.AIImageUpdate, ( image: Resources.AIImage ) => setKnownAIImages( knownAIImages.map( img => img.id === image.id ? image : img ) ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.AIImageDeleted, ( { id }: { id: string; } ) => setKnownAIImages( knownAIImages.filter( img => img.id !== id ) ) );

        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.AIImageLocked, ( { id }: { id: string; } ) => setLockedAIImages( [ ...lockedAIImages, id ] ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.AIImageUnlocked, ( { id }: { id: string; } ) => setLockedAIImages( lockedAIImages.filter( imgID => imgID !== id ) ) );

        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.InteractorAdded, ( interactor: Resources.Interactor ) => setKnownInteractors( [ ...knownInteractors, interactor ] ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.InteractorUpdate, ( interactor: Resources.Interactor ) => setKnownInteractors( knownInteractors.map( inter => inter.id === interactor.id ? interactor : inter ) ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.InteractorDeleted, ( { id }: { id: string; } ) => setKnownInteractors( knownInteractors.filter( inter => inter.id !== id ) ) );

        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.InteractorLocked, ( { id }: { id: string; } ) => setLockedInteractors( [ ...lockedInteractors, id ] ) );
        spaceEvents.addEventListener( SpaceEvents.SpaceEventType.InteractorUnlocked, ( { id }: { id: string; } ) => setLockedInteractors( lockedInteractors.filter( interID => interID !== id ) ) );
    }, [
        spaceEvents,
        knownDocuments, setKnownDocuments,
        knownInteractors, setKnownInteractors,
        knownAIImages, setKnownAIImages,
        currentInteractorID, setCurrentInteractorID,
        currentDocumentID, setCurrentDocumentID,
        lockedInteractors, setLockedInteractors,
        lockedAIImages, setLockedAIImages,
    ] );

    const addMemebertoSpace = useCallback(
        async ( userID: string ) =>
        {
            if ( !spaceID || !space ) return;
            const newSpace = await ottAI.spaces.addSpaceMember( spaceID, userID );
            setSpace( newSpace );
            setKnownUsers( await Promise.all( newSpace.members.map( member => ottAI.users.getUser( member ) ) ) );
        },
        [ ottAI, spaceID, space, setSpace ]
    );

    const removeMemberFromSpace = useCallback(
        async ( userID: string ) =>
        {
            if ( !spaceID || !space ) return;
            const newSpace = await ottAI.spaces.removeSpaceMember( spaceID, userID );
            setSpace( newSpace );
            setKnownUsers( await Promise.all( newSpace.members.map( member => ottAI.users.getUser( member ) ) ) );
        },
        [ ottAI, spaceID, space, setSpace ]
    );

    const createTextDocument = useCallback(
        async ( newDoc: Requests.TextDocumentPostRequest ) =>
        {
            if ( !spaceID ) return;
            const document = await ottAI.textDocuments.createTextDocument( spaceID, newDoc );
            setKnownDocuments( [ ...knownDocuments, document ] );
            return document.id;
        },
        [ ottAI, spaceID, knownDocuments, setKnownDocuments ]
    );

    const updateTextDocument = useCallback(
        async ( docID: string, update: Requests.TextDocumentPatchRequest ) => 
        {
            if ( !spaceID ) return;
            const document = await ottAI.textDocuments.updateTextDocument( spaceID, docID, update );
            setKnownDocuments( knownDocuments.map( doc => doc.id === docID ? document : doc ) );
        },
        [ ottAI, spaceID, knownDocuments, setKnownDocuments ]
    );

    const deleteTextDocument = useCallback(
        async ( docID: string ) =>
        {
            if ( !spaceID ) return;
            await ottAI.textDocuments.deleteTextDocument( spaceID, docID );
            setKnownDocuments( knownDocuments.filter( doc => doc.id !== docID ) );
        },
        [ ottAI, spaceID, knownDocuments, setKnownDocuments ]
    );

    const createAIImage = useCallback(
        async ( newImage: Requests.AIImagePostRequest ) =>
        {
            if ( !spaceID ) return;
            const image = await ottAI.images.createAIImage( spaceID, newImage );
            setKnownAIImages( [ ...knownAIImages, image ] );
            return image.id;
        },
        [ ottAI, spaceID, knownAIImages, setKnownAIImages ]
    );

    const updateAIImage = useCallback(
        async ( imageID: string, update: Requests.AIImagePatchRequest ) =>
        {
            if ( !spaceID ) return;
            const image = await ottAI.images.updateAIImage( spaceID, imageID, update );
            setKnownAIImages( knownAIImages.map( image => image.id === imageID ? image : image ) );
        },
        [ ottAI, spaceID, knownAIImages, setKnownAIImages ]
    );

    const deleteAIImage = useCallback(
        async ( imageID: string ) =>
        {
            if ( !spaceID ) return;
            await ottAI.images.deleteAIImage( spaceID, imageID );
            setKnownAIImages( knownAIImages.filter( image => image.id !== imageID ) );
        },
        [ ottAI, spaceID, knownAIImages, setKnownAIImages ]
    );


    const createInteractor = useCallback(
        async ( newInteractor: Requests.InteractorPostRequest ) =>
        {
            if ( !spaceID ) return;
            const interactor = await ottAI.interactors.createInteractor( spaceID, newInteractor );
            setKnownInteractors( [ ...knownInteractors, interactor ] );
            return interactor.id;
        },
        [ ottAI, spaceID, knownInteractors, setKnownInteractors ]
    );

    const updateInteractor = useCallback(
        async ( interactorID: string, update: Requests.InteractorPatchRequest ) =>
        {
            if ( !spaceID ) return;
            const newInteractor = await ottAI.interactors.updateInteractor( spaceID, interactorID, update );
            setKnownInteractors( knownInteractors.map( interactor => interactor.id === interactorID ? newInteractor : interactor ) );
        },
        [ ottAI, spaceID, knownInteractors, setKnownInteractors ]
    );

    const deleteInteractor = useCallback(
        async ( interactorID: string ) =>
        {
            if ( !spaceID ) return;
            await ottAI.interactors.deleteInteractor( spaceID, interactorID );
            setKnownInteractors( knownInteractors.filter( interactor => interactor.id !== interactorID ) );
        },
        [ ottAI, spaceID, knownInteractors, setKnownInteractors ]
    );

    return {
        loading,
        space, spaceEvents, onlineUsers,
        knownUsers, knownDocuments, knownAIImages, knownInteractors, knownModels,
        lockedInteractors, lockedAIImages,
        currentInteractorID, currentDocumentID,
        addMemebertoSpace, removeMemberFromSpace,
        createTextDocument, updateTextDocument, deleteTextDocument,
        createAIImage, updateAIImage, deleteAIImage,
        createInteractor, updateInteractor, deleteInteractor,
        setCurrentInteractorID, setCurrentDocumentID
    } as const;
}