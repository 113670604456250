import { Requests, Resources } from "@otter-co/ottai-shared";
import { BaseResource } from "./BaseResource";

import { SpaceEventEmitter } from '../SpaceEventEmitter';

export class Spaces extends BaseResource<Resources.Space>
{
    public async createSpace ( space: Requests.SpacePostRequest )
    {
        return await this._client.fetchAPI<Resources.Space>( 'spaces', {
            method: 'POST',
            body: JSON.stringify( space ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async getSpaces ()
    {
        return await this._client.fetchAPI<Resources.Space[]>( 'spaces' );
    }

    public async getSpace ( spaceId: string )
    {
        return await this._client.fetchAPI<Resources.Space>( `spaces/${ spaceId }` );
    }

    public async updateSpace ( spaceId: string, space: Requests.SpacePatchRequest )
    {
        return await this._client.fetchAPI<Resources.Space>( `spaces/${ spaceId }`, {
            method: 'PATCH',
            body: JSON.stringify( space ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async addSpaceMember ( spaceId: string, userId: string )
    {
        return await this._client.fetchAPI<Resources.Space>( `spaces/${ spaceId }/members/${ userId }`, { method: 'POST', } );
    }

    public async removeSpaceMember ( spaceId: string, userId: string )
    {
        return await this._client.fetchAPI<Resources.Space>( `spaces/${ spaceId }/members/${ userId }`, { method: 'DELETE', } );
    }

    public async deleteSpace ( spaceId: string )
    {
        return await this._client.fetchAPI( `spaces/${ spaceId }`, { method: 'DELETE' } );
    }

    public subscribe ( spaceID: string )
    {
        return new SpaceEventEmitter( this._client, spaceID );
    }
}