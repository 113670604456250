import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Resources } from '@otter-co/ottai-shared';

import './MessageView.Style.scss';

import { MarkDownView } from '../../Basic/MarkDownView';
import { useCheckboxValue } from '../../../state/hooks/useCheckboxValue';

export const MessageView = ( {
    message,
    knownUsers = [],
    interactor,
}: PropsWithChildren<{
    message?: Resources.ChatMessage;
    knownUsers?: Resources.User[];
    interactor?: Resources.Interactor | null;
}> ) => 
{
    const user = useMemo(
        () => knownUsers.find( u => u.id === message?.fromID ),
        [ message, knownUsers ]
    );

    const messageTypeClass = useMemo(
        () => 
        {
            switch ( message?.messageType )
            {
                default:
                case 0:
                    return 'Text';
                case 1:
                    return 'FunctionResult';
                case 2:
                    return 'Error';
            }
        },
        [ message ]
    );

    const ProperContent = useMemo(
        () =>
        {
            switch ( message?.messageType )
            {
                default:
                case 0:
                    return <TextMessageView message={ message as Resources.ChatTextMessage } />;
                case 1:
                    return <FunctionResultView message={ message } />;
                case 2:
                    return <ErrorMessageView message={ message as Resources.ChatErrorMessage } />;
            }
        },
        [ message, interactor ]
    );

    const properName = message?.fromType === 0
        ? user?.displayName
        : interactor?.name;

    return (
        <div className={ `MessageView ${ messageTypeClass }` }>
            <header className="Name">{ properName }</header>
            { ProperContent }
        </div>
    );
};

export const ErrorMessageView = ( {
    message,
}: PropsWithChildren<{
    message?: Resources.ChatErrorMessage;
}> ) => 
{
    return (
        <>
            <span className="Content">{ JSON.stringify( message?.error ) }</span>
        </>
    );
};

export const TextMessageView = ( {
    message,
}: PropsWithChildren<{
    message?: Resources.ChatTextMessage;
}> ) => 
{
    const [ showArguments, setShowArgumentsValue, ] = useCheckboxValue( false );

    return (
        <>
            <main>
                <MarkDownView markdown={ message?.content } />
            </main>

            <span className='FunctionSpan'>
                { message?.functionName &&
                    <>
                        <span className="FunctionName">{ message?.functionName }</span>
                        <label>
                            <span>Show Arguments: </span>
                            <input type="checkbox" checked={ showArguments } onChange={ setShowArgumentsValue } />
                        </label>
                    </> }
            </span>

            { showArguments &&
                <p className="Content">
                    { message?.functionArguments
                        ? JSON.stringify( message?.functionArguments, null, 2 )
                        : message?.rawFunctionArguments ?? "" }
                </p> }
        </>
    );
};

export const FunctionResultView = ( {
    message,
}: PropsWithChildren<{
    message?: Resources.ChatFunctionResultMessage;
}> ) => 
{
    const [ showResults, setShowResultsValue, ] = useCheckboxValue( false );
    return (
        <>
            <header>
                <span className="FunctionName">{ message?.functionName }</span>
                <label>
                    <span>Show Results: </span>
                    <input type="checkbox" checked={ showResults } onChange={ setShowResultsValue } />
                </label>
            </header>
            { showResults &&
                <p className="Content">{ JSON.stringify( message?.functionResponse, null, 2 ) }</p> }
        </>
    );
};