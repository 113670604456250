import { SpaceEvents } from '@otter-co/ottai-shared';
import { OttAIClient } from './OttAIClient';

export interface SpaceEventEmitter extends EventSource 
{
    addEventListener<SpaceEventType extends SpaceEvents.SpaceEventType> ( type: SpaceEventType, listener: ( args: SpaceEvents.SpaceEventData[ SpaceEventType ] ) => void, options?: boolean | AddEventListenerOptions ): void;
    addEventListener ( type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions ): void;
}
export class SpaceEventEmitter extends EventSource 
{

    private _funcMap: Map<Function, Function> = new Map();

    constructor (
        private _ottAI: OttAIClient,
        public readonly spaceID: string
    )
    {
        const url = `${ _ottAI.options.host }/api/spaces/${ spaceID }/subscribe`;
        super( url, { withCredentials: true } );
    }

    public addEventListener<SpaceEventType extends SpaceEvents.SpaceEventType> ( type: SpaceEventType, listener: ( args: SpaceEvents.SpaceEventData[ SpaceEventType ] ) => void, options?: boolean | AddEventListenerOptions ): void 
    {
        if ( this._funcMap.has( listener ) ) return;

        const func = ( event: MessageEvent ) => 
        {
            const data = JSON.parse( event.data ) as SpaceEvents.SpaceEventData[ SpaceEventType ];
            listener( data );
        };

        this._funcMap.set( listener, func );

        super.addEventListener( type, func, options );
    };

    public removeEventListener<SpaceEventType extends SpaceEvents.SpaceEventType> ( type: SpaceEventType | EventSourceEventMap, listener: ( args: SpaceEvents.SpaceEventData[ SpaceEventType ] ) => void, options?: boolean | EventListenerOptions ): void
    {
        if ( !this._funcMap.has( listener ) ) return;

        const func = this._funcMap.get( listener );
        super.removeEventListener( type as any, func as any, options );
    }
}