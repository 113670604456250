import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Resources } from '@otter-co/ottai-shared';

import './UserList.style.scss';
import { useInputValue } from '../../../state/hooks/useInputValue';
import { SpaceEventEmitter } from '../../../../lib/ottAI/SpaceEventEmitter';

export const UserList = ( {
    disabled = false,
    currentUserID = '',
    spaceEvents = null,
    knownUsers = [],
    onlineUsers = [],
    onUserAdd = () => { },
    onRemoveUser = () => { }
}: PropsWithChildren<{
    disabled?: boolean;
    spaceEvents?: SpaceEventEmitter | null,
    currentUserID?: string;
    knownUsers?: Resources.User[];
    onlineUsers?: string[];
    onUserAdd?: ( userID: string ) => void;
    onRemoveUser?: ( userID: string ) => void;
}> ) => 
{
    const [ newUserID, setNewUserIDValue, setNewUserID ] = useInputValue( '' );

    const users = useMemo(
        () => knownUsers.map( user =>
        {
            const isCurrentUser = user.id === currentUserID;

            const online = isCurrentUser
                ? spaceEvents?.readyState === spaceEvents?.OPEN
                : onlineUsers.includes( user.id );

            return (
                <span className='UserStatus' key={ user.id }>
                    <span className={ `StatusIcon ${ online ? 'Online' : 'Offline' } ${ isCurrentUser ? 'CurrentUser' : '' }` } />
                    <span className="UserName">{ user.displayName }</span>
                    { currentUserID !== user.id &&
                        <span className='RemoveUser' onClick={ () => onRemoveUser( user.id ) }>X</span>
                    }
                </span>
            );
        } ),
        [ knownUsers, onlineUsers, currentUserID, onRemoveUser, spaceEvents ]
    );

    const onSubmit = useCallback(
        ( event: React.FormEvent<HTMLFormElement> ) =>
        {
            event.preventDefault();
            onUserAdd( newUserID );
            setNewUserID( '' );
        },
        [ newUserID, onUserAdd, setNewUserID ]
    );

    const disableAdd = (
        disabled ||
        newUserID === ''
    );

    return (
        <div className="UserList">
            <div className="UserContainer">
                { users }
            </div>
            <form onSubmit={ onSubmit }>
                <input type="text" placeholder='' value={ newUserID } onChange={ setNewUserIDValue } />
                <button disabled={ disableAdd }>Add User</button>
            </form>
        </div>
    );
};