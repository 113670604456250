import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Requests, Resources } from '@otter-co/ottai-shared';

import './App.Style.scss';
import './App.Visual.Style.scss';

import { OttAIClientContext } from './state/contexts/ottAIClientContext';

import { UserDisplay } from './components/Users/UserDisplay/UserDisplay';
import { UserLogin } from './components/Users/UserLogin/UserLogin';
import { InteractorList } from './components/Interactors/InteractorList/InteractorList';
import { InteractorView } from './components/Interactors/InteractorView/InteractorView';
import { SpaceList } from './components/Space/SpaceList/SpaceList';
import { useSpaceState } from './state/bigHooks/useSpaceState';
import { DocumentPicker } from './components/Documents/DocumentPicker/DocumentPicker';
import { DocumentView } from './components/Documents/DocumentView/DocumentView';
import { UserList } from './components/Users/UserList/UserList';

function getFromLocation ( hash = window.location.hash.slice( 1 ) ): [ string, string ]
{
    const parts = hash.split( '/' ) as string[];

    if ( parts.length === 1 )
        return [ parts[ 0 ], '' ];
    else
        return [ parts[ 0 ], parts[ 1 ] ];
}

export const App = () => 
{
    const ottAI = useContext( OttAIClientContext );

    const [ currentUserID, setCurrentUserID ] = useState<string | null>( null );
    const [ currentSpaceID, setCurrentSpaceID ] = useState<string>( '' );

    const {
        loading,
        spaceEvents,
        knownUsers, onlineUsers,
        knownDocuments, knownAIImages,
        knownInteractors, knownModels,
        lockedAIImages, lockedInteractors,
        currentDocumentID, currentInteractorID,
        addMemebertoSpace, removeMemberFromSpace,
        createTextDocument, updateTextDocument, deleteTextDocument,
        createInteractor, updateInteractor, deleteInteractor,
        createAIImage, updateAIImage, deleteAIImage,
        setCurrentInteractorID, setCurrentDocumentID
    } = useSpaceState( ottAI, currentUserID, currentSpaceID );

    useEffect( () =>
    {
        function updateCurrent () 
        {
            const [ spaceID, documentID ] = getFromLocation();

            setCurrentSpaceID( spaceID );
            setCurrentDocumentID( documentID );
        }

        updateCurrent();
        window.addEventListener( 'hashchange', updateCurrent );

        return () => window.removeEventListener( 'hashchange', updateCurrent );

    }, [ currentSpaceID, currentDocumentID, setCurrentSpaceID, setCurrentDocumentID ] );

    useEffect( () => 
    {
        if ( !currentUserID )
            ottAI.users
                .getCurrentUser()
                .then( user => setCurrentUserID( user.id ) )
                .catch( () => setCurrentUserID( null ) );
    },
        [ ottAI, currentUserID, setCurrentUserID ]
    );

    const loginCallback = useCallback(
        ( userID: string ) => setCurrentUserID( userID ),
        [ setCurrentUserID ]
    );

    const setSpaceCallback = useCallback(
        ( spaceID: string ) => window.location.hash = `${ spaceID }/`,
        [ setCurrentSpaceID, setCurrentDocumentID ]
    );

    const currentDocument = useMemo( () =>
        [ ...knownDocuments, ...knownAIImages ].find( doc => doc.id === currentDocumentID ),
        [ currentDocumentID, knownDocuments, knownAIImages ]
    );

    const currentInteractor = useMemo(
        () => knownInteractors.find( interactor => interactor.id === currentInteractorID ),
        [ knownInteractors, currentInteractorID ]
    );

    const createAndSetTextDocument = useCallback(
        async ( data: Requests.TextDocumentPostRequest ) =>
            setCurrentDocumentID( ( await createTextDocument( data ) )! ),
        [ createTextDocument, setCurrentDocumentID ]
    );

    const createAndSetInteractor = useCallback(
        async ( data: Requests.InteractorPostRequest ) =>
            setCurrentInteractorID( ( await createInteractor( data ) )! ),
        [ createInteractor, setCurrentInteractorID ]
    );

    return (
        <>
            <div className='Header'>
                { currentUserID ? ( <>
                    <UserDisplay userID={ currentUserID } />
                    <UserList
                        spaceEvents={ spaceEvents }
                        knownUsers={ knownUsers } currentUserID={ currentUserID } onlineUsers={ onlineUsers }
                        onUserAdd={ addMemebertoSpace } onRemoveUser={ removeMemberFromSpace }
                    />
                    <SpaceList
                        currentUserID={ currentUserID! } currentSpaceID={ currentSpaceID }
                        onSpaceChange={ setSpaceCallback }
                    />
                </> ) : ( <>
                    <div className="UserDisplay">
                        <h2>OttAI</h2>
                        <p>An AI Text Document Tool</p>
                    </div>
                    <UserLogin onLoginSuceed={ loginCallback } />
                </> ) }
            </div>

            <div className="TextDocumentDisplay">
                { currentSpaceID &&
                    <DocumentPicker
                        spaceEvents={ spaceEvents } knownUsers={ knownUsers } knownTextDocuments={ knownDocuments } knownAIImages={ knownAIImages }
                        lockedAIImages={ lockedAIImages }
                        currentSpaceID={ currentSpaceID } currentDocumentID={ currentDocumentID }
                        // onDocumentSelect={ setCurrentDocumentID }
                        onDocCreate={ createAndSetTextDocument } onDocSave={ updateTextDocument } onDocDelete={ deleteTextDocument }
                        onImageCreate={ createAIImage } onImageSave={ updateAIImage } onImageDelete={ deleteAIImage }

                    /> }
                { currentDocument &&
                    <DocumentView
                        disabled={ loading }
                        lockedAIImages={ lockedAIImages }
                        spaceEvents={ spaceEvents } spaceID={ currentSpaceID } targetDocument={ currentDocument }
                        onDocSave={ updateTextDocument } onImageSave={ updateAIImage }
                    /> }
            </div>

            <div className="InteractorDisplay">
                { currentSpaceID &&
                    <InteractorList
                        interactors={ knownInteractors } currentInteractorID={ currentInteractorID }
                        lockedInteractors={ lockedInteractors }
                        onChange={ setCurrentInteractorID } onCreate={ createAndSetInteractor } onDelete={ deleteInteractor }
                    /> }
                { currentInteractor &&
                    <InteractorView
                        spaceID={ currentSpaceID } spaceEvents={ spaceEvents }
                        lockedInteractors={ lockedInteractors }
                        knownUsers={ knownUsers } knownModels={ knownModels } interactor={ currentInteractor }
                        onSave={ updateInteractor }
                    /> }
            </div>
        </>
    );
};