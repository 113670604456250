import React, { ChangeEvent, FormEvent, PropsWithChildren, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Requests, Resources, SpaceEvents } from '@otter-co/ottai-shared';

import './InteractorView.Style.scss';

import { OttAIClientContext } from '../../../state/contexts/ottAIClientContext';
import { ChatView } from '../../ChatMessages/ChatView/ChatView';
import { ChatInput } from '../../ChatMessages/ChatInput/ChatInput';

import { useInputValue } from '../../../state/hooks/useInputValue';
import { useToggle } from '../../../state/hooks/useToggleValue';
import { SpaceEventEmitter } from '../../../../lib/ottAI/SpaceEventEmitter';

type ChatHistoryAction =
    { type: "reset", payload: Resources.ChatMessage[]; } |
    { type: "add", payload: Resources.ChatMessage[]; } |
    { type: "update", payload: Resources.ChatMessage; } |
    { type: "delete", payload: { id: string; }; };

function ChatHistoryReducer ( state: Resources.ChatMessage[] = [], action: ChatHistoryAction )
{
    switch ( action.type )
    {
        case "add":
            return [ ...state, ...action.payload ];
        case "update":
            return state.map( m => m.id === action.payload.id ? action.payload : m );
        case "delete":
            return state.filter( m => m.id !== action.payload.id );
        case "reset":
            return action.payload;
        default:
            return state;
    }
};

export const InteractorView = ( {
    disabled = false,
    spaceID = null,
    spaceEvents = null,
    knownModels = [],
    knownUsers = [],
    lockedInteractors = [],
    interactor = null,
    onSave = () => { },
}: PropsWithChildren<{
    disabled?: boolean;
    spaceID?: string | null;
    spaceEvents?: SpaceEventEmitter | null;
    knownModels?: Resources.OttModel[];
    knownUsers?: Resources.User[];
    lockedInteractors?: string[];
    interactor?: Resources.Interactor | null;
    onSave?: ( id: string, interactor: Requests.InteractorPatchRequest ) => void;
}> ) => 
{
    const ottAI = useContext( OttAIClientContext );

    const [ loading, setLoading ] = useState( false );

    const [ settingsOpen, toggleSettings ] = useToggle( false );

    const [ interactorName, setInteractorNameValue, setInteractorName ] = useInputValue( '' );
    const [ interactorDescription, setInteractorDescriptionValue, setInteractorDescription ] = useInputValue( '' );
    const [ interactorPreload, setInteractorPreloadValue, setInteractorPreload ] = useInputValue( '' );

    const [ chatHistory, dispatch ] = useReducer( ChatHistoryReducer, [], );

    useEffect( () =>
    {
        setInteractorName( interactor?.name ?? '' );
        setInteractorDescription( interactor?.description ?? '' );
        setInteractorPreload( interactor?.preload ?? '' );
    }, [
        setInteractorName, setInteractorDescription, setInteractorPreload,
        interactor?.name, interactor?.description, interactor?.preload
    ] );

    useEffect(
        () => 
        {
            if ( !spaceID || !interactor ) return;

            setLoading( true );
            ottAI.chatMessages
                .getChatHistory( spaceID!, interactor.id )
                .then( messages => dispatch( { type: "reset", payload: messages } ) )
                .finally( () => setLoading( false ) );
        },
        [ ottAI, interactor, spaceID, dispatch, setLoading ],
    );

    useEffect(
        () => 
        {
            if ( !spaceEvents || !interactor?.id ) return;

            const addMessage = ( message: Resources.ChatMessage ) =>
                message.interactorID === interactor?.id && dispatch( { type: "add", payload: [ message ] } );

            const updateMessage = ( message: Resources.ChatMessage ) =>
                message.interactorID === interactor?.id && dispatch( { type: "update", payload: message } );

            const deleteMessage = ( { id }: { id: string; } ) =>
                dispatch( { type: "delete", payload: { id } } );

            const clearHistory = () => dispatch( { type: "reset", payload: [] } );

            spaceEvents.addEventListener( SpaceEvents.SpaceEventType.ChatMessageAdded, addMessage );
            spaceEvents.addEventListener( SpaceEvents.SpaceEventType.ChatMessageUpdate, updateMessage );
            spaceEvents.addEventListener( SpaceEvents.SpaceEventType.ChatMessageDeleted, deleteMessage );
            spaceEvents.addEventListener( SpaceEvents.SpaceEventType.ChatHistoryCleared, clearHistory );

            return () =>
            {
                spaceEvents.removeEventListener( SpaceEvents.SpaceEventType.ChatMessageAdded, addMessage );
                spaceEvents.removeEventListener( SpaceEvents.SpaceEventType.ChatMessageUpdate, updateMessage );
                spaceEvents.removeEventListener( SpaceEvents.SpaceEventType.ChatMessageDeleted, deleteMessage );
                spaceEvents.removeEventListener( SpaceEvents.SpaceEventType.ChatHistoryCleared, clearHistory );
            };
        },
        [ spaceEvents, dispatch, interactor?.id ]
    );

    const saveSettings = useCallback(
        ( e: FormEvent ) =>
        {
            e.preventDefault();

            if ( !interactor ) return;

            onSave( interactor?.id, {
                name: interactorName,
                description: interactorDescription,
                preload: interactorPreload,
            } );
        },
        [ onSave, interactor, interactorName, interactorDescription, interactorPreload ]
    );

    const setModel = useCallback(
        ( e: ChangeEvent<HTMLSelectElement> ) => interactor && onSave( interactor.id, { ottModelID: e.target.value } ),
        [ onSave, interactor ]
    );

    const getResponse = useCallback(
        async () =>
        {
            if ( !interactor ) return;

            setLoading( true );
            await ottAI.interactors.GetInteractorResponse( spaceID!, interactor.id );
            setLoading( false );
        },
        [ ottAI, setLoading, dispatch, spaceID, interactor ]
    );

    const clearHistory = useCallback(
        async () =>
        {
            if ( !interactor ) return;

            setLoading( true );
            await ottAI.chatMessages.clearChatHistory( spaceID!, interactor.id );
            dispatch( { type: 'reset', payload: [] } );
            setLoading( false );
        },
        [ ottAI, setLoading, dispatch, spaceID, interactor ]
    );

    const sendNewMessage = useCallback(
        async ( content: string, autoResponse: boolean ) => 
        {
            if ( !interactor ) return;

            setLoading( true );
            const message = await ottAI.chatMessages.createTextChatMessage( spaceID!, interactor.id, { content, functionName: null, functionArguments: null } );

            // dispatch( { type: 'add', payload: message } );

            if ( autoResponse )
            {
                const response = await ottAI.interactors.GetInteractorResponse( spaceID!, interactor.id );

                // dispatch( { type: 'add', payload: response } );
            }

            setLoading( false );
        },
        [ ottAI, setLoading, dispatch, spaceID, interactor ]
    );

    const modelOptions = useMemo(
        () => knownModels.map( m =>
            <option key={ m.id } value={ m.id }>{ m.name }</option>
        ), [ knownModels ] );

    const currentModel = useMemo(
        () => knownModels.find( m => m.id === interactor?.ottModelID ),
        [ knownModels, interactor ]
    );

    const interactorLocked = (
        interactor?.locked ||
        lockedInteractors.includes( interactor?.id ?? '' )
    );

    const inputDisabled = (
        disabled ||
        loading ||
        !interactor ||
        interactorLocked
    );

    const disableClearHistory = (
        chatHistory?.length === 0
    );

    const saveDirty = (
        interactorName !== interactor?.name ||
        interactorDescription !== interactor?.description ||
        interactorPreload !== interactor?.preload
    );

    return (
        <div className="InteractorView">
            <header>
                <button disabled={ inputDisabled } onClick={ toggleSettings }>{ settingsOpen ? "Close Settings" : "Open Settings" }</button>
                { ( settingsOpen && !inputDisabled ) && (
                    <>
                        <button disabled={ inputDisabled || !saveDirty } onClick={ saveSettings }>Save Settings</button>
                        <form id="InteractorSettings">
                            <label>
                                <div>Interactor: </div>
                                <input disabled={ inputDisabled } onChange={ setInteractorNameValue } value={ interactorName } />
                            </label>
                            <label>
                                <div>Description: </div>
                                <textarea disabled={ inputDisabled } onChange={ setInteractorDescriptionValue } value={ interactorDescription } />
                            </label>
                            <label>
                                <div>Preload: </div>
                                <textarea disabled={ inputDisabled } onChange={ setInteractorPreloadValue } value={ interactorPreload } />
                            </label>
                            <label>
                                <span>OttModel:</span>
                                <select disabled={ inputDisabled } value={ interactor?.ottModelID } onChange={ setModel }>
                                    { modelOptions }
                                </select>
                                <p>
                                    { currentModel?.description ?? "No model selected" }
                                </p>
                            </label>
                        </form>
                    </>
                ) }
                <button disabled={ inputDisabled || disableClearHistory } onClick={ clearHistory }>Clear History</button>
                <button disabled={ inputDisabled } onClick={ getResponse }>Get Response</button>

            </header>

            <ChatView knownUsers={ knownUsers } interactor={ interactor } messages={ chatHistory } />
            <ChatInput disabled={ inputDisabled } onInput={ sendNewMessage } />
        </div>
    );
};