import { Requests, Resources, Common } from '@otter-co/ottai-shared';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { SpaceEventEmitter } from '../../../../lib/ottAI/SpaceEventEmitter';
import { ListView } from './DocumentPickerViews/ListView';

import './DocumentPicker.style.scss';
import { useToggle } from '../../../state/hooks/useToggleValue';
import { useInputValue } from '../../../state/hooks/useInputValue';

export enum DocumentPickerMode 
{
    List,
    Tree,
    Grid
}

export const DocumentPicker = ( {
    spaceEvents,
    knownUsers = [],
    knownTextDocuments = [],
    knownAIImages = [],
    lockedAIImages = [],

    currentSpaceID,
    currentDocumentID,

    onDocCreate = () => { },
    onDocSave = () => { },
    onDocDelete = () => { },
    onImageCreate = () => { },
    onImageSave = () => { },
    onImageDelete = () => { },
}: PropsWithChildren<{
    spaceEvents?: SpaceEventEmitter | null;

    knownUsers?: Resources.User[];

    knownTextDocuments?: Resources.TextDocument[];
    knownAIImages?: Resources.AIImage[];

    lockedAIImages?: string[];

    currentSpaceID?: string | null;
    currentDocumentID?: string | null;

    onDocumentSelect?: ( docID: string ) => void;

    onDocCreate?: ( doc: Requests.TextDocumentPostRequest ) => void;
    onDocSave?: ( docID: string, doc: Requests.TextDocumentPatchRequest ) => void;
    onDocDelete?: ( docID: string ) => void;

    onImageCreate?: ( image: Requests.AIImagePostRequest ) => void;
    onImageSave?: ( imageID: string, image: Requests.AIImagePatchRequest ) => void;
    onImageDelete?: ( imageID: string ) => void;
}> ) => 
{
    const selectedDocument = useMemo( () => 
    {
        const possibleDocument = knownTextDocuments?.find( doc => doc.id === currentDocumentID );
        const possibleImage = knownAIImages?.find( img => img.id === currentDocumentID );

        return possibleDocument ?? possibleImage ?? null;
    }, [ currentDocumentID, knownTextDocuments, knownAIImages ] );

    const [ currentPath, setCurrentPath ] = useState<string>( '' );

    const [ createNewOpen, toggleCreateNewOpen, setCreateNewOpen ] = useToggle( false );
    const [ createNewTitle, setCreateNewTitleValue, setCreateNewTitle ] = useInputValue( 'New Document' );
    const [ createNewType, setCreateNewTypeValue, setCreateNewType ] = useInputValue( Common.ResourceTypes.TextDocument );

    const createNewDocument = useCallback( () => 
    {
        if ( !currentSpaceID ) return;
        if ( createNewType === Common.ResourceTypes.TextDocument )
        {
            onDocCreate( { name: createNewTitle } );
        }
        else if ( createNewType === Common.ResourceTypes.AIImage )
        {
            onImageCreate( { name: createNewTitle, description: "New Image", size: Resources.AiImageSize.Small } );
        }

        setCreateNewOpen( false );
        setCreateNewTitle( 'New Document' );
        setCreateNewType( Common.ResourceTypes.TextDocument );

    }, [
        createNewTitle, createNewType,
        onDocCreate, onImageCreate,
        setCreateNewOpen, setCreateNewTitle, setCreateNewType
    ] );

    const [ currentMode, setCurrentMode ] = useState<DocumentPickerMode>( DocumentPickerMode.List );
    const setListMode = useCallback( () => setCurrentMode( DocumentPickerMode.List ), [ setCurrentMode ] );
    const setTreeMode = useCallback( () => setCurrentMode( DocumentPickerMode.Tree ), [ setCurrentMode ] );
    const setGridMode = useCallback( () => setCurrentMode( DocumentPickerMode.Grid ), [ setCurrentMode ] );

    const onDocumentSelect = useCallback( ( docID: string ) => window.location.hash = `${ currentSpaceID }/${ docID }`, [ currentSpaceID ] );

    const selectorDisplay = useMemo( () =>
    {
        switch ( currentMode )
        {
            case DocumentPickerMode.List:
                return (
                    <ListView
                        currentDocumentID={ currentDocumentID }
                        knownTextDocuments={ knownTextDocuments } knownAIImages={ knownAIImages } knownUsers={ knownUsers }
                        onSelect={ onDocumentSelect } onDeleteDocument={ onDocDelete } onDeleteAIImage={ onImageDelete }
                    />
                );
            default:
                return <div>Not implemented</div>;
        }
    }, [ currentDocumentID, currentMode, currentPath, knownTextDocuments, knownAIImages, onDocumentSelect, onDocDelete, onImageDelete ] );

    const clearSelection = useCallback( () => onDocumentSelect( '' ), [ onDocumentSelect ] );

    return (
        <div className='DocumentPicker'>
            <header>
                <span>
                    <button disabled={ !currentDocumentID } onClick={ clearSelection }>Back</button>
                    <input type="text" value={ selectedDocument?.name ?? currentPath } placeholder="Path" onChange={ () => { } } />
                </span>

                { !currentDocumentID && (
                    <button onClick={ toggleCreateNewOpen }>
                        { createNewOpen ? 'Cancel' : 'Create New' }
                    </button>
                ) }

                { !currentDocumentID && createNewOpen && ( <>
                    <input type="text" placeholder='New Document Title' value={ createNewTitle } onChange={ setCreateNewTitleValue } />
                    <select value={ createNewType } onChange={ setCreateNewTypeValue }>
                        <option value={ Common.ResourceTypes.TextDocument }>Text Document</option>
                        <option value={ Common.ResourceTypes.AIImage }>AI Image</option>
                    </select>
                    <button onClick={ createNewDocument }>Create</button>
                </> ) }

                { !currentDocumentID && ( <>
                    <div>
                        <button disabled={ currentMode === DocumentPickerMode.List } onClick={ setListMode }>List</button>
                        <button disabled={ currentMode === DocumentPickerMode.Tree } onClick={ setTreeMode }>Tree</button>
                        <button disabled={ currentMode === DocumentPickerMode.Grid } onClick={ setGridMode }>Grid</button>
                    </div>
                </> ) }
            </header>

            { !currentDocumentID && selectorDisplay }
        </div>
    );
};