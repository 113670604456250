import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Resources } from '@otter-co/ottai-shared';
import { MessageView } from '../MessageView/MessageView';

import "./ChatView.Style.scss";
import { ScrollIntoView } from '../../Basic/ScrollIntoView';

export const ChatView = ( {
    messages = [],
    interactor,
    knownUsers
}: PropsWithChildren<{
    messages?: Resources.ChatMessage[];
    interactor: Resources.Interactor | null;
    knownUsers: Resources.User[];
}> ) => 
{
    const ref = useRef<HTMLDivElement>( null );

    useEffect(
        () =>
        {
            ref.current?.scrollIntoView( { behavior: 'smooth' } );
        }
    );

    const messageViews = useMemo(
        () => messages.map( message =>
            <MessageView key={ message.id } interactor={ interactor } message={ message } knownUsers={ knownUsers } />
        ),
        [ messages, knownUsers, interactor ]
    );

    return (
        <div className="ChatView">
            { messageViews }
            <span ref={ ref } />
        </div>
    );
};