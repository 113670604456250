import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import './AIImageView.style.scss';
import { Requests, Resources } from '@otter-co/ottai-shared';
import { useToggle } from '../../../state/hooks/useToggleValue';
import { OttAIClientContext } from '../../../state/contexts/ottAIClientContext';
import { SpaceEventEmitter } from '../../../../lib/ottAI/SpaceEventEmitter';
import { useInputValue } from '../../../state/hooks/useInputValue';

export const AIImageView = ( {
    disabled = false,
    spaceEvents = null,
    spaceID = null,
    lockedImages = [],
    image = null,

    onSave = () => { },
}: PropsWithChildren<{
    disabled?: boolean;
    spaceEvents?: SpaceEventEmitter | null;
    spaceID?: string | null;
    lockedImages?: string[];
    image: Resources.AIImage | null;

    onSave?: ( imageID: string, data: Requests.AIImagePatchRequest ) => void;
}> ) => 
{
    const ottAI = useContext( OttAIClientContext );

    const [ loading, setLoading ] = useState<boolean>( false );

    const imageURL = useMemo( () =>
        spaceID && image
            ? ottAI.images.getImageURL( spaceID, image.id, image.imageURLs[ image.imageURLs.length - 1 ] )
            : null,
        [ image?.imageURLs[ image?.imageURLs.length - 1 ], spaceID ]
    );

    const [ settingsOpen, toggleSettingsOpen ] = useToggle( false );

    const [ imageTitle, setImageTitleValue, setImageTitle ] = useInputValue( '' );
    const [ imageDescription, setImageDescriptionValue, setImageDescription ] = useInputValue( '' );
    const [ imageSize, setImageSizeValue, setImageSize ] = useInputValue( Resources.AiImageSize.Small );

    useEffect( () => 
    {
        setImageTitle( image?.name ?? '' );
        setImageDescription( image?.descriptionHistory[ image?.descriptionHistory?.length - 1 ] ?? '' );
        setImageSize( image?.size ?? Resources.AiImageSize.Small );
    }, [ image?.name, image?.descriptionHistory, setImageTitle, setImageDescription ] );

    const saveSettings = useCallback( () =>
        image && onSave( image?.id, {
            name: imageTitle,
            description: imageDescription,
            size: imageSize as Resources.AiImageSize,
        } ),
        [ image?.id, imageTitle, imageDescription, imageSize, onSave, ]
    );

    const generateImage = useCallback( () => 
    {
        main();
        async function main ()
        {
            if ( !spaceID || !image?.id ) return;

            setLoading( true );

            await ottAI.images.generateImage( spaceID, image?.id );

            setLoading( false );
        }
    }, [ ottAI, spaceID, image?.id, setLoading ] );

    const manuallyUnlock = useCallback( () =>
    {
        main();
        async function main ()
        {
            if ( !spaceID || !image?.id ) return;

            setLoading( true );

            await ottAI.images.updateAIImage( spaceID, image?.id, { locked: false, customLockMessage: null } );

            setLoading( false );
        }
    }, [ ottAI, spaceID, image?.id, setLoading ] );

    const settingsDirty = (
        imageTitle !== image?.name ||
        imageDescription !== image?.descriptionHistory[ image?.descriptionHistory?.length - 1 ] ||
        imageSize !== image?.size
    );

    const imageLocked = (
        image?.locked ||
        lockedImages.includes( image?.id ?? '' )
    );

    const disableInput = (
        disabled ||
        loading ||
        imageLocked
    );

    return (
        <div className="AIImageView">
            <header>
                <button disabled={ disableInput } onClick={ toggleSettingsOpen }>
                    { settingsOpen
                        ? 'Close Settings'
                        : 'Open Settings' }
                </button>
                { settingsOpen && (
                    <>
                        <button disabled={ disableInput || !settingsDirty } onClick={ saveSettings }>Save Settings</button>
                        <form>
                            <label>
                                <span>Image Name</span>
                                <input disabled={ disableInput } type="text" value={ imageTitle } onChange={ setImageTitleValue } />
                            </label>
                            <label>
                                <span>Image Description</span>
                                <textarea disabled={ disableInput } value={ imageDescription } onChange={ setImageDescriptionValue } />
                            </label>
                            <label>
                                <span>Image Size</span>
                                <select disabled={ disableInput } value={ imageSize } onChange={ setImageSizeValue }>
                                    <option value={ Resources.AiImageSize.Small }>Small</option>
                                    <option value={ Resources.AiImageSize.Medium }>Medium</option>
                                    <option value={ Resources.AiImageSize.Large }>Large</option>
                                </select>
                            </label>
                        </form>
                    </>
                ) }
                <button disabled={ disableInput || imageLocked } onClick={ generateImage }>Generate</button>

                { imageLocked && <>
                    <button onClick={ manuallyUnlock }>Manually Unlock</button>
                    <span>Image is locked: { image?.lockReason ? Resources.AIImageLockReason[ image?.lockReason! ] : image?.customLockMessage }</span>
                </> }
            </header>

            { image?.generated && imageURL &&
                <div className="GeneratedImage">
                    <img src={ imageURL } />
                </div>
            }
        </div>
    );
};