import { Resources, Requests } from '@otter-co/ottai-shared';
import { BaseResource } from './BaseResource';

export class AIImages extends BaseResource<Resources.AIImage>
{
    public async getKnownModels ( spaceID: string )
    {
        return await this._client.fetchAPI<Resources.OttModel[]>( `spaces/${ spaceID }/interactors/models` );
    }

    public async createAIImage ( spaceID: string, image: Requests.AIImagePostRequest )
    {
        return await this._client.fetchAPI<Resources.AIImage>( `spaces/${ spaceID }/images`, {
            method: 'POST',
            body: JSON.stringify( image ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async getAIImagesForSpace ( spaceID: string )
    {
        return await this._client.fetchAPI<Resources.AIImage[]>( `spaces/${ spaceID }/images` );
    }

    public async getAIImage ( spaceID: string, imageID: string )
    {
        return await this._client.fetchAPI<Resources.AIImage>( `spaces/${ spaceID }/images/${ imageID }` );
    }

    public async updateAIImage ( spaceID: string, imageID: string, image: Requests.AIImagePatchRequest )
    {
        return await this._client.fetchAPI<Resources.AIImage>( `spaces/${ spaceID }/images/${ imageID }`, {
            method: 'PATCH',
            body: JSON.stringify( image ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async deleteAIImage ( spaceID: string, imageID: string )
    {
        return await this._client.fetchAPI( `spaces/${ spaceID }/images/${ imageID }`, {
            method: 'DELETE'
        } );
    }

    public async generateImage ( spaceID: string, imageID: string )
    {
        return await this._client.fetchAPI<Resources.AIImage>( `spaces/${ spaceID }/images/${ imageID }/generate` );
    }

    public getImageURL ( spaceID: string, imageID: string, fileName?: string )
    {
        return (
            fileName
                ? `${ this._client.options.host }/api/spaces/${ spaceID }/images/${ imageID }/image/${ fileName }`
                : `${ this._client.options.host }/api/spaces/${ spaceID }/images/${ imageID }/image`
        );
    }
}