import { Resources, Requests, OttFunctions } from "@otter-co/ottai-shared";
import { BaseResource } from "./BaseResource";

export class ChatMessages extends BaseResource<Resources.ChatMessage>
{
    public async createTextChatMessage ( spaceID: string, interactorID: string, message: Requests.ChatTextMessagePostRequest )
    {
        return await this._client.fetchAPI<Resources.ChatMessage[]>( `spaces/${ spaceID }/interactors/${ interactorID }/messages`, {
            method: 'POST',
            body: JSON.stringify( message ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async getChatHistory ( spaceID: string, interactorID: string ) 
    {
        return await this._client.fetchAPI<Resources.ChatMessage[]>( `spaces/${ spaceID }/interactors/${ interactorID }/messages` );
    }

    public async getChatMessage ( spaceID: string, interactorID: string, messageID: string )
    {
        return await this._client.fetchAPI<Resources.ChatMessage>( `spaces/${ spaceID }/interactors/${ interactorID }/messages/${ messageID }` );
    }

    public async deleteChatMessage ( spaceID: string, interactorID: string, messageID: string ) 
    {
        return await this._client.fetchAPI( `spaces/${ spaceID }/interactors/${ interactorID }/messages/${ messageID }`, {
            method: 'DELETE'
        } );
    }

    public async clearChatHistory ( spaceID: string, interactorID: string ) 
    {
        return await this._client.fetchAPI( `spaces/${ spaceID }/interactors/${ interactorID }/messages`, {
            method: 'DELETE'
        } );
    }
}