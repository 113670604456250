import { PropsWithChildren, useEffect, useMemo } from 'react';
import mermaid from 'mermaid';
import { Converter } from 'showdown';
import mm from 'showdown-mermaid';

const converter = new Converter();
converter.addExtension( mm );
mermaid.initialize( { startOnLoad: false } );

export const MarkDownView = ( { markdown }: PropsWithChildren<{ markdown?: string; }> ) => 
{
    const markdownHTML = useMemo(
        () => ( { __html: markdown ? converter.makeHtml( markdown ) : '' } ),
        [ markdown ]
    );

    useEffect( () => { mermaid.run(); } );

    return ( <span className="MarkDownView" dangerouslySetInnerHTML={ markdownHTML } /> );
};