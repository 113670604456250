import { Requests, Resources } from '@otter-co/ottai-shared';
import { BaseResource } from './BaseResource';

export class Users extends BaseResource<Resources.User>
{
    public async createUser ( user: Requests.UserPostRequest )
    {
        return await this._client.fetchAuth<Resources.User>( 'create', {
            method: 'POST',
            body: JSON.stringify( user ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async authenticate ( username: string, password: string ) 
    {
        return await this._client.fetchAuth<Resources.User>( 'login', {
            method: 'POST',
            body: JSON.stringify( { username, password } ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }

    public async logout () 
    {
        return await this._client.fetchAuth( 'logout' );
    }


    public async getCurrentUser ()
    {
        return await this._client.fetchAPI<Resources.User>( 'users' );
    }

    public async getUser ( userId: string )
    {
        return await this._client.fetchAPI<Resources.User>( `users/${ userId }` );
    }

    public async updateUser ( userId: string, user: Requests.UserPatchRequest )
    {
        return await this._client.fetchAPI<Resources.User>( `users/${ userId }`, {
            method: 'PATCH',
            body: JSON.stringify( user ),
            headers: {
                'Content-Type': 'application/json'
            }
        } );
    }
}