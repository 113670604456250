import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { OttAIClientContext } from '../../../state/contexts/ottAIClientContext';
import { Resources } from '@otter-co/ottai-shared';
import { useInputValue } from '../../../state/hooks/useInputValue';

import './SpaceList.Style.scss';

export const SpaceList = ( {
    currentUserID,
    currentSpaceID,
    onSpaceChange = () => { },
}: PropsWithChildren<{
    currentUserID?: string;
    currentSpaceID?: string;
    onSpaceChange?: ( spaceID: string ) => void;
}> ) => 
{
    const ottAI = useContext( OttAIClientContext );

    const [ loading, setLoading ] = useState( false );
    const [ spaces, setSpaces ] = useState<Resources.Space[]>( [] );

    const [ newSpaceName, setNewSpaceNameValue, setNewSpaceName ] = useInputValue( '' );

    useEffect(
        () =>
        {
            setLoading( true );
            ottAI.spaces
                .getSpaces()
                .then( setSpaces )
                .finally( () => setLoading( false ) );
        },
        [ ottAI, setSpaces, setLoading, currentUserID ]
    );

    const setCurrentSpaceID = useCallback(
        ( e: React.ChangeEvent<HTMLSelectElement> ) =>
        {
            const spaceID = e.target.value;
            onSpaceChange( spaceID );
        },
        [ onSpaceChange ]
    );

    const createSpace = useCallback(
        ( e: React.FormEvent<HTMLFormElement> ) =>
        {
            e.preventDefault();

            setLoading( true );
            ottAI.spaces
                .createSpace( { name: newSpaceName } )
                .then( ( newSpace ) => onSpaceChange( newSpace.id ) )
                .then( () => ottAI.spaces.getSpaces() )
                .then( setSpaces )
                .then( () => setNewSpaceName( '' ) )
                .finally( () => setLoading( false ) );
        },
        [ ottAI, setSpaces, setLoading, onSpaceChange, setNewSpaceName, newSpaceName ]
    );

    const deleteSpace = useCallback( () =>
    {
        setLoading( true );
        if ( !currentUserID || !currentSpaceID ) return;

        ottAI.spaces
            .deleteSpace( currentSpaceID! )
            .then( () => ottAI.spaces.getSpaces() )
            .then( setSpaces )
            .finally( () => setLoading( false ) );
    }, [ ottAI, setLoading, setSpaces, currentUserID, currentSpaceID ] );

    const spaceOptions = useMemo( () => spaces && spaces.map && spaces?.map( space =>
        <option key={ space.id } value={ space.id }>{ space.name }</option>
    ), [ spaces ] );

    const newDocEnabled = !newSpaceName || loading;

    return (
        <div className="SpaceList">
            <form onSubmit={ createSpace }>
                <input type="text" placeholder="New Space Name" onChange={ setNewSpaceNameValue } value={ newSpaceName } />
                <button disabled={ newDocEnabled }>New Space</button>
            </form>
            <span>
                <select onChange={ setCurrentSpaceID } value={ currentSpaceID }>
                    <option value="">Select a Space</option>
                    { spaceOptions }
                </select>
                <button disabled={ !currentSpaceID } onClick={ deleteSpace }>Delete Space</button>
            </span>
        </div>
    );
};