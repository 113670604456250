import { useCallback, useState } from "react";

type CompatibleInputEvent = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>;

export const useInputValue = ( initialValue: string ) => 
{
    const [ value, setValue ] = useState( initialValue );

    const setEventValue = useCallback( ( event: CompatibleInputEvent ) => setValue( event.target.value ), [ setValue ] );

    return [
        value,
        setEventValue,
        setValue
    ] as const;
};