import React, { FormEvent, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { useInputValue } from '../../../state/hooks/useInputValue';
import { OttAIClientContext } from '../../../state/contexts/ottAIClientContext';

import './UserLogin.style.scss';
import { useToggle } from '../../../state/hooks/useToggleValue';

export const UserLogin = (
    {
        onLoginSuceed = () => { },
        onLoginFailed = () => { },
    }: PropsWithChildren<{
        onLoginSuceed?: ( userID: string ) => void;
        onLoginFailed?: ( error: Error ) => void;
    }>,
) => 
{
    const ottAI = useContext( OttAIClientContext );

    const [ loading, setLoading ] = useState( false );

    const [ createUser, , , toggleCreateUserInput ] = useToggle( false );

    const [ createToken, setUserCreateInput, setUserCreate ] = useInputValue( '' );
    const [ displayName, setDisplayNameInput, setDisplayName ] = useInputValue( '' );
    const [ email, setEmailInput, setEmail ] = useInputValue( '' );

    const [ username, inputSetUsername, setUsername ] = useInputValue( '' );
    const [ password, inputSetPassword, setPassword ] = useInputValue( '' );

    const handleLoginSubmit = useCallback(
        ( e: FormEvent ) => 
        {
            e.preventDefault();

            setLoading( true );
            ottAI.users
                .authenticate( username, password )
                .then( user => onLoginSuceed( user.id ) )
                .catch( onLoginFailed )
                .finally( () =>
                {
                    setUsername( '' );
                    setPassword( '' );
                    setLoading( false );
                } );
        },
        [
            ottAI, username, password,
            setLoading,
            setUsername, setPassword, onLoginSuceed, onLoginFailed
        ]
    );

    const handleCreateSubmit = useCallback(
        ( e: FormEvent ) => 
        {
            e.preventDefault();
            setLoading( true );
            ottAI.users
                .createUser( { username, password, createToken, displayName, email } )
                .then( user => onLoginSuceed( user.id ) )
                .catch( onLoginFailed )
                .finally( () =>
                {
                    setUsername( '' );
                    setPassword( '' );
                    setUserCreate( '' );
                    setDisplayName( '' );
                    setEmail( '' );
                    setLoading( false );
                } );
        },
        [
            ottAI,
            username, password,
            createToken, displayName, email,
            setLoading,
            setUsername, setPassword, setUserCreate, setDisplayName, setEmail,
            onLoginSuceed, onLoginFailed

        ]
    );

    return (
        <form className="UserLogin" onSubmit={ createUser ? handleCreateSubmit : handleLoginSubmit }>
            <span>
                <label>
                    <input type="text" placeholder="Username" onChange={ inputSetUsername } value={ username } />
                </label>
                <label>
                    Create
                    <input type="checkbox" checked={ createUser } onChange={ toggleCreateUserInput } />
                </label>
            </span>
            <span>
                <label>
                    <input type="password" placeholder="Password" onChange={ inputSetPassword } value={ password } />
                </label>
                <button type="submit">
                    { createUser ? 'Create' : 'Login' }
                </button>
            </span>
            { createUser &&
                <>
                    <input type="text" placeholder='User Create Code' value={ createToken } onChange={ setUserCreateInput } />
                    <input type="text" placeholder="Display Name" value={ displayName } onChange={ setDisplayNameInput } />
                    <input type="text" placeholder="Email" value={ email } onChange={ setEmailInput } />
                </>
            }
        </form>
    );
};